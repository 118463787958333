import React from "react";
import style from "./style.module.css";
import { loading } from "../../Assets";
import Headlines from "../Headlines/Headlines";
const Processing = ({
  text = "Please wait for a moment",
  textClassName,
  imageClassName,
  imageUrl,
}) => {
  return (
    <div
      className={`position-absolute flex-center flex-column ${style["processing-style"]}`}
    >
      <img className={`${style["loading-image"]}`} src={loading} alt="" />
      <Headlines className="loading-text-home margin-top20" text={text} />
    </div>
  );
};
export default Processing;
