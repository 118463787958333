import React from "react";
import { iBLogo } from "../../Assets";
import { isMobile } from "../../Utils/utils";
import Headlines from "../Headlines/Headlines";
import style from "./styles.module.css";
import gradientText from "../../Assets/images/gradient.png";
const NavHeader = () => {
  return (
    <div
      className={`d-flex  ${
        isMobile
          ? "padding-left-right16 padding-top16 gap12"
          : "align-items-center padding-top8 justify-content-center"
      }`}
    >
      <img
        className={`${isMobile ? `${style["logo"]}` : `${style["logo"]}`}`}
        src={iBLogo}
        alt=""
      />
      <div
        className={`d-flex flex-d-col justify-content-center  ${
          isMobile ? "" : "align-items-left"
        }`}
      >
        <Headlines
          className={`textColorWhite ${style["text-style"]}`}
          text="JAB ENJOY KARNA HO SUPERSTAAR PERFORMANCE,"
        />
        <img className={`${style["gradient"]}`} src={gradientText} alt="" />
      </div>

      {/* <p className="text-white"> JAB ENJOY KARNA HO SUPERSTAAR PERFORMANCE, EK TOH BANTA HAI!</p> */}
    </div>
  );
};
export default NavHeader;
