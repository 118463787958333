import React, { useEffect } from "react";
import { REDIRECT_URL } from "../../Utils/constant";
const RedirectPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = REDIRECT_URL;
    }, 100);
  }, []);
  return <></>;
};
export default RedirectPage;
