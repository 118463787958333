import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import "./Assets/common.css";
import RedirectPage from "./Pages/Home/RedirectPage";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectPage />} />
        <Route path="/:phoneNumber" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
