import axios from "axios";
import { CLIENT_BASE_URL } from "../Utils/constant";
// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: CLIENT_BASE_URL, // Replace with your API domain
  // timeout: 250000, // Request timeout
});

// Set up request interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    // Perform actions before the request is sent, like setting auth tokens
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Set up response interceptors
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // You can handle errors or redirect on certain conditions
    if (error.response && error.response.status === 401) {
      console.log("error occoured at api:", error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
