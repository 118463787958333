import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { cameraClick } from "../../Assets";
import Button from "../../Components/Buttom/Button";
import NavHeader from "../../Components/NavHeader/NavHeader";
import Processing from "../../Components/Processing/Processing";
import axiosService from "../../Service/ApiCall";
import clientAxiosService from "../../Service/ClientApiCall.js";
import { isMobile } from "../../Utils/utils";
import { REDIRECT_URL } from "../../Utils/constant.js";
import "./style.css";

const Home = () => {
  const { phoneNumber = "" } = useParams();
  // console.log(phoneNumber);
  const webcamRef = useRef(null);
  const weBCamStyle = {
    width: "100%",
    height: "57%",
    backgroundColor: "#000000",
    border: "2px solid #d9b64d",
  };
  const [imgSrc, setImgSrc] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [thankYouImage, setThankYouImage] = useState("");
  const [downloadImage, setDownloadImage] = useState("");

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  // const takePhoto = async () => {
  //   setBase64Str(emptyStr);
  //   // setBase64Str((prev) => [...prev, empty]);
  //   // console.log("Base 64 take before photo=====>")
  //   const width = 1920;
  //   const height = width / (16 / 9);

  //   let video = videoRef.current;
  //   let photo = photoRef.current;
  //   let frame = new Image();
  //   frame.src = photoFrame;

  //   // let canvas = document.createElement('canvas')
  //   let ctx = photo.getContext("2d");
  //   photo.width = width;
  //   photo.height = height;
  //   // ctx.save();
  //   // ctx.drawFocusIfNeeded()
  //   ctx.drawImage(video, 0, 0, width, height);
  //   frame.onload = function () {
  //     ctx.drawImage(frame, 0, 0, width, height);
  //     let base64Str1 = photo.toDataURL().split(";base64,")[1];
  //     console.log("imge...........", base64Str1);
  //     setBase64Str(photo.toDataURL());
  //     $(".camera").addClass("hide");

  //     setHasPhoto(true);
  //   };
  //   // ctx.restore();
  //   console.log("After Click=====>", base64String);
  // };
  // const downloadPhoto = async () => {
  //   const a = document.createElement("a");
  //   a.href = base64String;
  //   a.download = `${Math.round(+new Date() / 1000)}-Image.png`;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // };

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        setTimeout(async () => {
          // Make the GET request for mobile validation after the delay
          const mobileNumber = phoneNumber;
          const mobileResponse = await clientAxiosService.get(
            `/mobilevalidateapi.php?mobile=${mobileNumber}`
          );

          console.log(
            "Mobile validation response:",
            mobileResponse?.data.message
          );

          if (mobileResponse?.data.message === "Invalid Mobile Number") {
            window.location.href = REDIRECT_URL;
          } else {
            setIsloading(false);
          }
        }, 2000);
      } catch (ex) {
        setIsloading(false);
        console.log("Exception at mobile validation:", ex);
      }
    };
    fetchData();
  }, []);

  const submitImage = async () => {
    if (imgSrc) {
      setIsloading(true);
      try {
        const base64 = imgSrc.split(",")[1];
        const payload = {
          mobile: +phoneNumber,
          image: base64,
        };
        const response = await axiosService.post(
          "/imperialblue/update-photo",
          payload
        );

        console.log(response);
        if (response?.data?.result === "Success") {
          setIsloading(false);
          setThankYouImage(response?.data?.ai_image_url);
          setDownloadImage(response?.data?.downloadUrl);
          const aiImageUrl = response?.data?.ai_image_url;

          const getResponse = await clientAxiosService.get(
            `/imperialdataapi.php?mobile=${phoneNumber}&imageurl=${aiImageUrl}`
          );
          console.log("clent response: ", getResponse);
        } else {
          setIsloading(false);
          alert("Something went wrong. Please submit again.");
        }
      } catch (ex) {
        setIsloading(false);
        console.log("Exception at submitting image", ex);
        alert("Something went wrong. Please submit again.");
      }
    }
  };

  const retakeImage = () => {
    setImgSrc("");
  };

  const handleDownloadImage = async () => {
    const time = new Date().getTime();
    const payload = {
      mobile: +phoneNumber,
      time: time,
    };

    const response = await axiosService.post(
      "/imperialblue/update-download-time",
      payload
    );
    console.log(response);
    if (downloadImage) {
      const downloadImg = document.createElement("a");
      downloadImg.href = downloadImage;
      downloadImg.download = `downloaded-image.png`;
      document.body.appendChild(downloadImg);
      downloadImg.click();
      document.body.removeChild(downloadImg);
    }
  };

  return (
    <>
      <div className="bg">
        <NavHeader />
        <div
          className={`${
            isMobile
              ? "padding-left-right24 margin-top20"
              : "flex-center margin-top16"
          }`}
        >
          {!imgSrc && !thankYouImage && (
            <div className="webcam-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                mirrored
                screenshotFormat="image/jpeg"
                style={`${isMobile}` ? weBCamStyle : ""}
                className={`${!isMobile ? "webcam" : ""}`}
              />
              <div className="image w-100 d-flex justify-content-center margin-top16">
                <img src={cameraClick} onClick={capture} alt="" />
              </div>
            </div>
          )}
          {imgSrc && !thankYouImage && (
            <div className="capturedImage-container">
              <img src={imgSrc} alt="" className="capturedImage" />
              <div className="w-100 d-flex justify-content-center gap10 margin-top20">
                <Button
                  className={`actions-buttons`}
                  onClick={retakeImage}
                  title={"Retake"}
                />
                <Button
                  className={`actions-buttons`}
                  onClick={submitImage}
                  title={"Submit"}
                />
              </div>
            </div>
          )}
          {/* <div className="flex-center flex-column loading-margin">
          <div className="flex-center">
            <img className="loading-icon" src={loading} alt="loading" />
          </div>
          <Headlines
            className="loading-text-home margin-top20"
            text="Please wait for a moment"
          />
        </div> */}
        </div>
        {isLoading && <Processing />}
      </div>
      {thankYouImage && (
        <div className="bg2">
          <div className="blurry-background">
            <NavHeader />
            <div className="thankyou-container">
              <img
                className="thank-you-img"
                src={thankYouImage}
                alt=""
                srcSet=""
              />
              <Button
                className={"actions-buttons"}
                title={"DOWNLOAD"}
                onClick={handleDownloadImage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
